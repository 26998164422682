.gallery-section {
    .section-title {
        text-align: center;
        margin: 0 auto 40px;
        max-width: 700px;
    }

    .gallery-img {
        margin-bottom: 20px;
    }
}

@media screen and (max-width : 768px) {
    
    #imagefourMobile{
        display: none;
    }

}